// Import variable styles and global styles
@import "../../../assets/styles/variables.scss";
@import "../../../assets/styles/global.scss";

// Styling for the DropdownMenu component
.dropdown_menu {
  position: absolute;
  right: 0;
  top: 42px;
  background-color: rgba(0, 0, 0, 0.4);
  border: 2px solid $basicOrange; // Border color
  border-radius: 20px; // Rounded corners
  color: $basicWhite; // Text color
  padding: 15px; // Padding around content
  z-index: 1; // Ensure it appears above other elements
  display: flex;
  flex-direction: column;
  max-width: 250px; // Maximum width
  min-width: 180px; // Minimum width

  > a {
    text-align: center;
    text-decoration: none;
    color: $basicWhite; // Link text color
    display: flex;
    column-gap: 5px;
    cursor: pointer;
    @extend .whiteUnderlineHover; // Extend the hover underline style
  }
}

// Divider line between menu items
.line {
  margin: 5px 0;
  width: 100%;
  height: 1px;
  background: $basicOrange; // Line color
}

// Styling for buttons inside the dropdown menu
.btn_item {
  text-align: center;
  text-decoration: none;
  color: $basicWhite; // Button text color
  display: flex;
  column-gap: 5px;
  cursor: pointer;
  @extend .whiteUnderlineHover; // Extend the hover underline style
  :hover {
    color: $basicWhite; // Hover color change
  }
}

// Styling for the wallet information section
.wallet_info {
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
  gap: 5px;
  white-space: nowrap;
  cursor: pointer;
}

// Media query for responsive design on mobile screens
@media (max-width: $mobile-screen) {
  .dropdown_menu {
    left: 0;
    right: 0;
    max-width: 100%;
    margin: 15px; // Adjust margin for better spacing
  }
}
