// Define a variable for error color
$error: #d70000;

// Define variables for various shades of orange
$lightOrange: #feca57;
$basicOrange: #ff9f43;

// Define a variable for basic white color
$basicWhite: #ffffff;

// Define variables for basic dark and light blue colors
$basicDarkBlue: #11171f;
$basicLightBlue: #474a4e;

// Define variables for screen breakpoints
$default-screen: 1280px;
$large-screen: 1200px;
$medium-screen: 992px;
$small-screen: 768px;
$mobile-screen: 480px;
