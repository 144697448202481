/* Styles for the container of the loader */
.loader {
  position: relative;
  margin: 0 auto; /* Center the loader horizontally */
}

/* Styles for the circular spinner element */
.loader:before {
  content: ""; /* Create an empty content box */
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 7px solid #ccc; /* Border for the spinner */
  border-top-color: #000; /* Color of the top border to create a contrast */
  border-radius: 50%; /* Create a circular shape */
  animation: loader-spin 1s linear infinite; /* Apply the rotation animation */
}

/* Keyframes for the rotation animation */
@keyframes loader-spin {
  0% {
    transform: rotate(0deg); /* Initial rotation angle */
  }
  100% {
    transform: rotate(360deg); /* Final rotation angle for one complete rotation */
  }
}
