// Import variables
@import "../../assets/styles/variables.scss";
// Import global styles
@import "../../assets/styles/global.scss";

/* Styles for the footer section */
footer {
  width: 100%;
  background-color: #030408; // Background color using a variable
  color: $basicWhite; // Text color using a variable
  text-align: center;
  position: absolute;
  top: 100; // Assuming this is a pixel value, you might want to add 'px'
  z-index: 4;

  /* Styles for the container within the footer */
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem;
    gap: 4rem;

    /* Styles for the social media section within the container */
    &_social {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      /* Styles for the heading within the social media section */
      > h1 {
        margin-right: 1rem;
        text-wrap: nowrap;
      }

      /* Styles for links within the social media section */
      > a {
        display: flex;
        width: 45px;
        height: 34px;
        text-decoration: none;

        /* Hover effect for links within social media */
        &:hover {
          transition: all 0.2s ease-in-out;
          transform: scale(1.1);
        }

        /* Transition effect for links within social media when not hovered */
        &:not(:hover) {
          transition: all 0.2s ease-in-out;
          transform: scale(1);
        }
      }
    }

    /* Styles for the logo section within the container */
    &_logo {
      width: 15%;
    }
  }
}

/* Media query for small screens */
@media (max-width: $small-screen) {
  footer {
    .container {
      padding: 3rem;

      /* Styles for the social media section within the container for small screens */
      &_social {
        > h1 {
          font-size: 5vw; // Font size relative to the viewport width
        }
        > a {
          width: 5vw; // Width relative to the viewport width
          > img {
            width: 100%; // Image width set to 100%
          }
        }
      }

      /* Styles for the logo section within the container for small screens */
      &_logo {
        width: 50%;
      }
    }
  }
}

/* Media query for mobile screens */
@media (max-width: $mobile-screen) {
  footer {
    .container {
      /* Styles for the social media section within the container for mobile screens */
      &_social {
        > h1 {
          margin: 0; // Reset margin for heading
        }
      }
    }
  }
}
